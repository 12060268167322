.CategoryLabelContainer {
  display: flex;
  align-items: center;
  /* border: solid black 1px; */
  width: 100%;
  justify-content: space-between;
}

.CategoryLabel {
  font-weight: bold;
}

.CheckContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    /* border: solid black 1px; */
    width: 100%;
}