.box {
  display: inline-block;
  border: 1px solid black;
  padding: 10px 30px;
  margin: 0px 20px;
  width: 100px;
  text-align: center;
}
.box.hidden {
  visibility: hidden;
}

.lines {
  margin-left: 100px;
}

.line {
  display: inline-block;
  border: 1px solid black;
  border-top: none;
  height: 30px;
  width: 200px;
  margin-left: -1px;
  margin-right: 0;
}
.quuxBox{
  margin-top: 1.5rem;
  /* background-image: linear-gradient(to right, #000046, #002f73, #005a9c); */
  background-color: #5b6467;
  background-image: linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%);
  color: white;
  font-weight: bold;
}
.connect {
  height: 30px;
  border-right: 1px solid black;
}
.connect.three {
  width: 300px;
  margin-top: -5px;
}
.barBox{
	margin-top: 2rem;
  display: block;
  margin-left: 14rem;
  /* background-image: linear-gradient(to right, #000046, #002f73, #005a9c); */
  background-image: linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%);
  

  color: white;
  font-weight: bold;
}
.bazBox{
	margin-top: 5rem;
  margin-left: 25rem;
  /* background-image: linear-gradient(to right, #000046, #002f73, #005a9c); */
  background-image: linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%);
  font-weight: bold;
  color: white;
}
.fooBox{
	position: absolute;
  margin-top: 8rem;
  margin-left: 2rem;
  /* background-image: linear-gradient(to right, #000046, #002f73, #005a9c); */
  background-image: linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%);
  color: white;
  font-weight: bold;
}
.line1{
    transform: rotate(45deg);
    border-right: none;
    height: 4.5rem;
    width: 11rem;
    border-left: none;
}
.line2{
    transform: rotate(135deg);
    border-right: none;
    height: 1rem;
    width: 11.5rem;
    border-left: none;
    margin-left: 5rem;
}
.line3{
	transform: rotate(135deg);
    border-left: none;
    border-right: none;
    width: 11rem;
	  height: 4.5rem;
    display: block;
    margin-top: 4rem;
    position: absolute;
}
  .line4{
    transform: rotate(45deg);
    border-left: none;
    border-right: none;
    margin-left: 17rem;
    margin-top: 2.8rem;
    width: 9.5rem;
    position: absolute;
}