@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400&display=swap");

body {
  margin: 0;
  font-family: "Bai Jamjuree" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Bai Jamjuree" !important;
}

p {
  font-family: "Bai Jamjuree" !important;
}
div {
  font-family: "Bai Jamjuree" !important;
}
button {
  font-family: "Bai Jamjuree" !important;
}
span {
  font-family: "Bai Jamjuree" !important;
}
input {
  font-family: "Bai Jamjuree" !important;
}
